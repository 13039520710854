<template>
    <div>
      <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Fulltime -- TPC Roster Report Schedule"/>

        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="800px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('add')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-plus-circle</v-icon> Add New Roster Report Schedule
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">New report schedule</span>
            </v-card-title>

            <v-card-text class="py-0">
                <v-container>
                    <!-- Add new section -->
                    <v-form ref="form_new_template">
                    <v-row>
                        <v-col cols="12">
                            <v-radio-group
                                class="m-0"
                                v-model="newItem.new_template.report_type"
                                row
                                style="margin-top:0px;height:48px"
                                :rules=[validate_rules.required]
                                
                            >
                                <v-radio
                                    label="TPC - EQ humanica"
                                    value="excel_humanica_eq"
                                    style="margin-top:-10px;margin-right: 60px;"
                                ></v-radio>

                                <v-radio
                                    label="TPC - FR humanica"
                                    value="excel_humanica"
                                    style="margin-top:-10px;margin-right: 60px;"
                                >
                                </v-radio>
 
                                <v-radio
                                    label="TPC - Own rider"
                                    value="excell_tpc_own"
                                    style="margin-top:-10px;margin-right: 50px;"
                                ></v-radio>

                                <v-radio
                                    label="1112D - Own rider"
                                    value="excel_own1112"
                                    style="margin-top:10px;margin-right: 70px;"
                                ></v-radio>

                                <v-radio
                                    label="Franchise Report"
                                    value="text_humanica"
                                    style="margin-top:10px;"
                                ></v-radio>

                                <v-radio
                                    label="Share Service Report"
                                    value="excel_ssreport"
                                    style="margin-top:10px;margin-left: 55px;"
                                ></v-radio>

                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2"><div class="font-weight-regular text--primary mt-3">Start Date :: </div></v-col>
                        <v-col cols="10">
                            <v-radio-group
                                class="m-0"
                                v-model="newItem.new_template.start_date.type"
                                row
                                style="margin-top:0px;height:48px"
                                
                            >
                                <v-radio
                                    label="Day"
                                    value="day"
                                    style="margin-top:-10px"
                                >
                                </v-radio>
                                <v-select
                                :items="dayOp"
                                style="max-width:100px; margin-right:60px; height:50px"
                                v-model="newItem.new_template.start_date.day"
                                outlined
                                dense
                                ></v-select>
                                <v-radio
                                    label="Last of month"
                                    value="radio-2"
                                    style="margin-top:-10px"
                                ></v-radio>
                            </v-radio-group>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" class="pt-0"><div class="font-weight-regular text--primary mt-3">End Date :: </div></v-col>
                        <v-col cols="10" class="pt-0">
                            <v-radio-group
                                class="m-0"
                                v-model="newItem.new_template.end_date.type"
                                row
                                style="margin-top:0px;height:48px"
                                
                            >
                                <v-radio
                                    label="Day"
                                    value="day"
                                    style="margin-top:-10px"
                                >
                                </v-radio>
                                <v-select
                                :items="dayOp"
                                v-model="newItem.new_template.end_date.day"
                                style="max-width:100px; margin-right:60px; height:50px"
                                outlined
                                dense
                                ></v-select>
                                <v-radio
                                    label="Last of month"
                                    value="last"
                                    style="margin-top:-10px"
                                ></v-radio>
                                <v-checkbox
                                    style="margin-top:6px"
                                    v-model="newItem.new_template.end_date.is_next_month"
                                    :label="`Next month`"
                                ></v-checkbox>
                            </v-radio-group>
                    </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" class="pt-0"><div class="font-weight-regular text--primary mt-3">Execute Date :: </div></v-col>
                        <v-col cols="10" class="pt-0">
                            <v-radio-group
                                class="m-0"
                                v-model="newItem.new_template.execute_date.type"
                                row
                                style="margin-top:0px;height:48px"
                                :rules=[validate_rules.required]
                            >
                                <v-radio
                                    label="Day"
                                    value="day"
                                    style="margin-top:-10px"
                                >
                                </v-radio>
                                <v-select
                                :items="dayOp"
                                style="max-width:100px; margin-right:60px; height:50px"
                                v-model="newItem.new_template.execute_date.day"
                                outlined
                                dense
                                ></v-select>
                                <v-radio
                                    label="Last of month"
                                    value="last"
                                    style="margin-top:-10px"
                                ></v-radio>
                                <v-checkbox
                                    style="margin-top:6px"
                                    v-model="newItem.new_template.execute_date.is_next_month"
                                    :label="`Next month`"
                                ></v-checkbox>
                            </v-radio-group>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" sm="3" class="pt-0 pb-0">
                            <div class="font-weight-regular text--primary mt-3">Email To ::</div>
                        </v-col>
                        <v-col cols="9" sm="9" class="pt-0 pb-0">
                            <v-text-field dense outlined v-model="newItem.new_template.humanica_email_to_text" :rules="[validate_rules.email_comma_one]"> </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" sm="3" class="pt-0 pb-0">
                            <div class="font-weight-regular text--primary mt-3">Email CC ::</div>
                        </v-col>
                        <v-col cols="9" sm="9" class="pt-0 pb-0">
                            <v-text-field dense outlined v-model="newItem.new_template.humanica_email_cc_text" :rules="[validate_rules.email_comma]"> </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="9" sm="9" offset="3" class="pt-0">
                                <v-btn
                                    elevation="5"
                                    small
                                    color="primary"
                                    @click="saveNewTemplate()"
                                >
                                    Add
                                </v-btn>
                        </v-col>
                    </v-row>
                    </v-form>
                    <hr>
                    <v-row>
                        <v-col cols="2" sm="2" class="pt-0 pb-0">
                            <div class="font-weight-regular text--primary mt-3">Schedule ::</div>
                        </v-col>
                        <v-col cols="10" sm="10" >
                                <ol>
                                    <li v-for="(item, index) in newItem.templates" v-bind:key="index">
                                        <div>
                                            <span class="text--primary"> Start date :</span>{{ item.start_date.type == 'day' ? (item.start_date.day + " of month") : "Last of month" }} 
                                            <span class="text--primary"> End date :</span> 
                                            {{ 
                                                item.end_date.is_next_month ?
                                                ( item.end_date.type == 'day' ? (item.end_date.day + " of next month") : "Last of next month" ) :
                                                ( item.end_date.type == 'day' ? (item.end_date.day + " of month") : "Last of month" )
                                            }}
                                            <span class="text--primary"> Execute date :</span> 
                                            {{ 
                                                item.execute_date.is_next_month ?
                                                ( item.execute_date.type == 'day' ? (item.execute_date.day + " of next month") : "Last of next month" ) :
                                                ( item.execute_date.type == 'day' ? (item.execute_date.day + " of month") : "Last of month" )
                                            }}
                                            <v-btn color="error" elevation="2" x-small @click="deleteNewTemplate(index);">Del</v-btn>

                                        </div>
                                        <div>
                                            <span class="text--primary"> Email to :</span> {{item.humanica_email_to.join(", ")}}
                                        </div>
                                        <div>
                                            <span class="text--primary"> Email cc :</span> {{item.humanica_email_cc.join(", ")}}
                                        </div>
                                        <div>
                                            <span>Report Type : {{item.report_type}}</span>
                                        </div>
                                    </li>
                                </ol>
                        </v-col>
                    </v-row>
                    <hr>
                    <v-form ref="form_template_range">
                        <v-row>
                            <v-col cols="2" class="">
                                    <div class="font-weight-regular text--primary mt-3">Range ::</div>
                                </v-col>
                                <v-col cols="1" class="px-0">
                                    <div class="font-weight-regular mt-3 text-right">Start</div>
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <v-menu
                                        ref="menu_start_date"
                                        v-model="menu.start_date"
                                        :close-on-content-click="false"
                                        :return-value.sync="newItem.start_date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"

                                    >
                                        <template v-slot:activator="{ on, attrs }">

                                            <v-text-field
                                                v-model="newItem.start_date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="[validate_rules.required]"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="newItem.start_date"
                                            no-title
                                            scrollable
                                            type="month"
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu.start_date = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu_start_date.save(newItem.start_date)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="1" class="px-0">
                                    <div class="font-weight-regular mt-3 text-right">End</div>
                                </v-col>
                                <v-col cols="3" class="py-0">
                                    <v-menu
                                        ref="menu_end_date"
                                        v-model="menu.end_date"
                                        :close-on-content-click="false"
                                        :return-value.sync="newItem.end_date"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newItem.end_date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="[validate_rules.required]"
                                            ></v-text-field>
                                            </template>
                                            <v-date-picker
                                            v-model="newItem.end_date"
                                            no-title
                                            scrollable
                                            type="month"
                                            >
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="menu.end_date = false"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                text
                                                color="primary"
                                                @click="$refs.menu_end_date.save(newItem.end_date)"
                                            >
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>

                                </v-col>
                        </v-row>
                    </v-form>


                </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('add')" color="blue darken-1" text @click="save">Add</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->

          
        </v-dialog>
        <v-dialog v-model="dialogEdit" max-width="800px">
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Edit {{ edit_label }} :: {{ editItem.id }}</span>
            </v-card-title>

            <v-card-text class="py-0">
                <v-container>
                    <!-- Add new section -->
                    <v-form ref="form_new_template">
                    <v-row>
                        <v-col cols="2" ><div class="font-weight-regular text--primary mt-3">Start Date :: </div></v-col>
                        <v-col cols="3" class="pt-0">
                            <v-menu
                                ref="menu_edit_start_date"
                                v-model="menu.edit_start_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.start_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editItem.start_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.start_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu.edit_start_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu_edit_start_date.save(editItem.start_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" ><div class="font-weight-regular text--primary mt-3">End Date :: </div></v-col>
                        <v-col cols="3" class="pt-0">
                            <v-menu
                                ref="menu_edit_end_date"
                                v-model="menu.edit_end_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.end_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editItem.end_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.end_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu.edit_end_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu_edit_end_date.save(editItem.end_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2" ><div class="font-weight-regular text--primary mt-3">Execute Date :: </div></v-col>
                        <v-col cols="3" class="pt-0">
                            <v-menu
                                ref="menu_edit_execute_date"
                                v-model="menu.edit_execute_date"
                                :close-on-content-click="false"
                                :return-value.sync="editItem.execute_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="editItem.execute_date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        :rules="[validate_rules.required]"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="editItem.execute_date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="menu.edit_execute_date = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu_edit_execute_date.save(editItem.execute_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" sm="3" class="pt-0 pb-0">
                            <div class="font-weight-regular text--primary mt-3">Humanica Email To ::</div>
                        </v-col>
                        <v-col cols="9" sm="9" class="pt-0 pb-0">
                            <v-text-field dense outlined v-model="editItem.humanica_email_to_text" :rules="[validate_rules.email_comma_one]"> </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3" sm="3" class="pt-0 pb-0">
                            <div class="font-weight-regular text--primary mt-3">Humanica Email CC ::</div>
                        </v-col>
                        <v-col cols="9" sm="9" class="pt-0 pb-0">
                            <v-text-field dense outlined v-model="editItem.humanica_email_cc_text" :rules="[validate_rules.email_comma]"> </v-text-field>
                        </v-col>
                    </v-row>
                    </v-form>

                </v-container>

            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeItem">Cancel</v-btn>
              <v-btn v-if="canDo && canDo.can.includes('update')" color="blue darken-1" text @click="update">Update</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->

          
        </v-dialog>
      </v-toolbar> 



      <v-divider></v-divider>
        
      <div class="content-wrap">

            <v-row justify="end">
                <v-col cols="12" sm="6" md="6" >
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                </v-col>

            </v-row>

           <v-row>
                
              <v-col cols="12" sm="12" md="12" >
                    <v-data-table
                        :headers="headers"
                        :items="schedules"
                        :items-per-page="100"
                        :footer-props="footerProps"
                        class="elevation-1"
                        :sort-by="['id']"
                        :sort-desc="[true]"
                        :search="search"
                        dense
                        >
                            <template v-slot:item.template.report_type="{ item }">
                                <span>{{ item.template ? item.template.report_type == 'excel_humanica' ? 'TPC - FR humanica' : item.template.report_type == 'text_humanica' ? 'Franchise Report' : item.template.report_type == 'excel_own1112' ? '1112D - Own rider' : item.template.report_type == 'excell_tpc_own' ? 'TPC - Own rider' : item.template.report_type == 'excel_humanica_eq' ? 'TPC - EQ humanica' : item.template.report_type == 'excel_ssreport' ? 'Share Service Report' : '' : '' }}</span>
                            </template>

                            <template v-slot:item.humanica_to_emails="{ item }">
                                <span>{{ item.humanica_to_emails.join(", ") }}</span>
                            </template>
                            <template v-slot:item.humanica_cc_emails="{ item }">
                                <span>{{ item.humanica_cc_emails.join(", ") }}</span>
                            </template>
                            <template v-slot:item.last_execute_at="{ item }">
                                <span>{{ item.last_execute_at ? moment(item.last_execute_at).format("YYYY-MM-DD HH:mm") : "Not executed" }}</span>
                            </template>
                            <template v-slot:item.edit="{ item }">
                                <v-icon color="error darken-2" v-if="!(canDo && canDo.can.includes('update'))" > mdi-lock </v-icon>
                                <v-icon color="primary" v-if="(canDo && canDo.can.includes('update') && item.last_execute_at == null)" @click="showEditItem(item)" >
                                    mdi-pen
                                </v-icon>
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-icon color="error darken-2" v-if="!(canDo && canDo.can.includes('delete'))" > mdi-lock </v-icon>
                                <v-icon color="error" v-if="(canDo && canDo.can.includes('delete') && item.last_execute_at == null)" @click="deleteItem(item)" >
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.execute="{ item }">
                                <v-icon color="blue darken-2" v-if="!(canDo && canDo.can.includes('export'))" > mdi-lock </v-icon>
                                <v-icon v-if="(canDo && canDo.can.includes('export'))" @click="exportItem(item)" >
                                    mdi-email
                                </v-icon>
                            </template>

                            
                    </v-data-table>

              </v-col>
          </v-row>
      </div>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import lodash from 'lodash';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export default {
    name: 'FulltimePaymentSchedule',
    data: () => ( {
        expanded: [],
        singleExpand: false,
        dialog : false,
        dialogEdit : false,
        edit_label : '',
        selSchedule : null,
        showReportDialog : false,
        paymentSchedules : [],
        selItem : null,
        selSlot : null,
        userType : "DM",
        showSlotDialog : false,
        
        importResultLog: {
            isShow : false,
            message : ""
        },
        is_allow_import : true,
        schedules : [
            
        ],
        headersDate : [],
        search : "",
        payment_records : [],
        footerProps: {'items-per-page-options': [50, 100, 500]},

        headers: [
            { text: 'ID', value: 'id', sortable: false, filterable: false },
            { text: 'Report Type', value: 'template.report_type'},
            { text: 'Start Date', value: 'start_date' },
            { text: 'End Date', value: 'end_date'},
            { text: 'Execute Date', value: 'execute_date' },
            { text: 'Email To', value: 'humanica_to_emails' },
            { text: 'Email CC', value: 'humanica_cc_emails' },
            { text: 'Last Processed At', value: 'last_execute_at' },
            { text: 'Edit', value: 'edit', sortable: false, filterable: false, },
            { text: 'Delete', value: 'delete', sortable: false, filterable: false, },
            { text: 'Execute', value: 'execute', sortable: false, filterable: false, }
        ],

        menu: {
            start_date: false,
            end_date: false,
            execute_date: false,
            edit_start_date: false,
            edit_end_date: false,
            edit_execute_date: false
        },
        dayOp: [],
        editItem : {
            id : null,
            start_date : null,
            end_date : null,
            execute_date : null,
            humanica_email_to_text : "",
            humanica_email_cc_text : "",
            humanica_cc_emails : null,
            humanica_to_emails : null
        },
        newItem: {
            new_template : {
                start_date: { type : null, day : null},
                end_date: { type : null, day : null, is_next_month : null},
                execute_date: { type : null, day : null, is_next_month : null},
                report_type : "",
                humanica_email_to_text : "",
                humanica_email_cc_text : "",
                humanica_email_to : null,
                humanica_email_cc : null
            },
            templates : [],
            start_date : null,
            end_date : null
        },
        defaultItem: {
            new_template : {
                start_date: { type : null, day : null},
                end_date: { type : null, day : null, is_next_month : null},
                execute_date: { type : null, day : null, is_next_month : null},
                report_type : "",
                humanica_email_to_text : "",
                humanica_email_cc_text : "",
                humanica_email_to : null,
                humanica_email_cc : null
            },
            templates : [],
            start_date : null,
            end_date : null
        },
        validate_rules : {
            required: v => !!v || 'This field is required',
            filesize:  v => !v || v.size < 2048*1024 || 'Updload size should be less than 2 MB!',
            filetype:  v => {
                if(v){
                var ext = v.name.split('.').pop();
                    return ["xlsx"].includes(ext) || 'Please upload .xlsx file only';
                } else {
                    return 'This field is required'
                }
            },
            email_comma: v => {
                if (v.trim() == "")
                    return true;
                let emails = v.split(",");
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                for (let i = 0 ; i < emails.length ; i++)
                {
                    let email = emails[i].trim();
                    if (re.test(String(email).toLowerCase()) == false)
                    {
                        return 'Invalid Email';
                    }
                }

                return true;
            },
            email_comma_one: v => {
                if (v.trim() == "")
                    return 'Please insert at least a email';

                let emails = v.split(",");
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                let is_valid_one = false
                for (let i = 0 ; i < emails.length ; i++)
                {
                    let email = emails[i].trim();
                    if (re.test(String(email).toLowerCase()) == false)
                    {
                        return 'Invalid Email';
                    }
                    is_valid_one = true;
                }
                if (!is_valid_one)
                {
                    return 'Please insert at least a email';
                }
                return true;
            }
        },
    }),
    components: {
        pageHeading
    },
    methods: {
         ...mapActions(['genCan', 'getTPCRosterReportSchedule', 'addTPCRosterReportSchedule', 'updateTPCRosterReportSchedule', 'deleteTPCRosterReport', 'executeTPCRosterReport','getUserProfile']),
        isToday(inp_date) {
            const moment = require('moment-timezone');

            let ret_class = [];
            if (inp_date == moment().tz('Asia/Bangkok').format("YYYY-MM-DD"))
            {
                ret_class.push('amber');
                ret_class.push('lighten-4');
            }
            return ret_class;
        },
        close () {
            this.dialog = false
            setTimeout(() => {
                this.newItem = lodash.cloneDeep(this.defaultItem)
                this.$refs.form_new_template.resetValidation()
                this.$refs.form_template_range.resetValidation()
            })
        },
        closeItem () {
            this.dialogEdit = false
        },
        async save () {
            if(this.$refs.form_template_range.validate())
            {
                let end_month = moment(this.newItem.end_date + "-01", "YYYY-MM-DD");
                console.log("end_month", end_month);
                for (let i = 0 ; i < this.newItem.templates.length ; i++)
                {
                    let template = this.newItem.templates[i];
                    let template_id = uuidv4();
                    for (let cur_month = moment(this.newItem.start_date + "-01", "YYYY-MM-DD") ; cur_month.isSameOrBefore(end_month); cur_month.add(1, 'month'))
                    {
                        try
                        {
                            let {start, end, execute} = this.calReportDate(cur_month, template);
                            let obj = {
                                template : {
                                    id : template_id,
                                    ...template
                                },
                                "start_date": start.format("YYYY-MM-DD"),
                                "end_date": end.format("YYYY-MM-DD"),
                                "execute_date": execute.format("YYYY-MM-DD"),
                                "humanica_email_to": template.humanica_email_to,
                                "humanica_email_cc": template.humanica_email_cc,
                                "report_type" : template.report_type
                            }
                            await this.addTPCRosterReportSchedule(obj);
                        }
                        catch(e)
                        {
                            console.log("Error on ", template, e);
                        }
                    }
                }

                alert("Insert complete");
                this.close();
                this.reloadScheduleData();
                /*
                this.newItem.humanica_email_to = lodash.map(this.newItem.humanica_email_to_text.toString().split(","), function(item){ return item.trim(); });
                this.newItem.humanica_email_cc = this.newItem.humanica_email_cc_text ? lodash.map(this.newItem.humanica_email_cc_text.toString().split(","), function(item){ return item.trim(); }) : [];

                let result = await this.addTPCRosterReportSchedule(this.newItem);
                if (result.is_complete)
                {
                    this.close();
                    this.reloadScheduleData();
                }
                else
                {
                    alert(result.message);
                }
                */
            }

        },
        async update () {
            try
            {
                let obj = {
                    id : this.editItem.id,
                    "start_date": this.editItem.start_date,
                    "end_date": this.editItem.end_date,
                    "execute_date": this.editItem.execute_date,
                    humanica_email_to : lodash.map(this.editItem.humanica_email_to_text.toString().split(","), function(item){ return item.trim(); }),
                    humanica_email_cc : this.editItem.humanica_email_cc_text ? lodash.map(this.editItem.humanica_email_cc_text.toString().split(","), function(item){ return item.trim(); }) : []
                }
                let result = await this.updateTPCRosterReportSchedule(obj);
                if (result.is_complete)
                {
                    alert("Update Complete");
                    this.closeItem();
                    this.reloadScheduleData();
                }
                else
                {
                    alert(result.message);
                }
            }
            catch(e)
            {
                console.log("Error on ", this.editItem, e);
            }
        },
        async reloadScheduleData() {
            this.schedules = await this.getTPCRosterReportSchedule();
        },
        calReportDate(base_month_moment, template) {
            let start = base_month_moment.clone();
            if (template.start_date.type=="day")
            {
                start = moment(start.format("YYYY-MM-") + template.start_date.day.toString().padStart(2,"0"), "YYYY-MM-DD");
            }
            else
            {
                start = start.endOf('month');
            }

            let end = base_month_moment.clone();
            if (template.end_date.is_next_month)
                end.add(1, 'months');
            if (template.end_date.type=="day")
            {
                end = moment(end.format("YYYY-MM-") + template.end_date.day.toString().padStart(2,"0"), "YYYY-MM-DD");
            }
            else
            {
                end = end.endOf('month');
            }

            let execute = base_month_moment.clone();
            if (template.execute_date.is_next_month)
                execute.add(1, 'months');
            if (template.execute_date.type=="day")
            {
                execute = moment(execute.format("YYYY-MM-") + template.execute_date.day.toString().padStart(2,"0"), "YYYY-MM-DD");
            }
            else
            {
                execute = end.endOf('month');
            }
            return {start, end, execute};

        },
        async saveNewTemplate () {
            if(this.$refs.form_new_template.validate())
            {
                //validate date sequence
                if (this.newItem.new_template.start_date.type == "day" && Number.isInteger(parseInt(this.newItem.new_template.start_date.day)) === false)
                {
                    alert("Please select day of start date");
                    return;
                }
                if (this.newItem.new_template.execute_date.type == "day" && Number.isInteger(parseInt(this.newItem.new_template.execute_date.day)) === false)
                {
                    alert("Please select day of execute date");
                    return;
                }
                if (this.newItem.new_template.end_date.type == "day" && Number.isInteger(parseInt(this.newItem.new_template.end_date.day)) === false)
                {
                    alert("Please select day of end date");
                    return;
                }

                let {start, end, execute} = this.calReportDate(moment(), this.newItem.new_template)
                console.log(start, end, execute);

                if ( ! ( start.isBefore(end) && end.isBefore(execute) ) )
                {
                    alert("Invalid date sequence");
                    return;
                }

                this.newItem.templates.push({
                    ...lodash.cloneDeep(this.newItem.new_template),
                    humanica_email_to : lodash.map(this.newItem.new_template.humanica_email_to_text.toString().split(","), function(item){ return item.trim(); }),
                    humanica_email_cc : this.newItem.new_template.humanica_email_cc_text ? lodash.map(this.newItem.new_template.humanica_email_cc_text.toString().split(","), function(item){ return item.trim(); }) : [],
                    report_type : this.newItem.new_template.report_type
                });
            }
        },
        async showEditItem (item) {
            this.edit_label = item.template ? item.template.report_type == 'excel_humanica' ? 'TPC - FR humanica' : item.template.report_type == 'text_humanica' ? 'Franchise Report' : item.template.report_type == 'excel_own1112' ? '1112D - Own rider' : item.template.report_type == 'excell_tpc_own' ? 'TPC - Own rider' : item.template.report_type == 'excel_humanica_eq' ? 'TPC - EQ humanica' : item.template.report_type == 'excel_ssreport' ? 'Share Service Report' : '' : ''
            this.editItem = {
                id : item.id,
                start_date : item.start_date,
                end_date : item.end_date,
                execute_date : item.execute_date,
                humanica_email_to_text : item.humanica_to_emails.join(", "),
                humanica_email_cc_text : item.humanica_cc_emails.join(", "),
                humanica_cc_emails : item.humanica_cc_emails,
                humanica_to_emails : item.humanica_to_emails,
                report_type : item.report_type
            };
            this.dialogEdit = true;
        },
        deleteNewTemplate(index){
            let templates = [];
            for (let i = 0 ; i < this.newItem.templates.length ; i++)
            {
                if (i != index)
                    templates.push(this.newItem.templates[i]);
            }
            this.newItem.templates = templates;
        },
        async deleteItem(item) {
            if (confirm("Confirm to delete a schedule on " + item.start_date + " - " + item.end_date + "?"))
            {
                let result = await this.deleteTPCRosterReport({id : item.id});
                if (result.is_complete)
                    await this.reloadScheduleData();
                else
                    alert(result.message);
            }
        },
        async exportItem(item) {
            if (confirm("Confirm to execute a schedule on " + item.start_date + " - " + item.end_date + "?"))
            {
                let result = await this.executeTPCRosterReport({id : item.id});
                if (result.is_complete)
                    await this.reloadScheduleData();
                else
                    alert(result.message);
            }
        }
    },
    async beforeMount() {
        this.genCan();
        this.schedules = await this.getTPCRosterReportSchedule();
        this.dayOp.push("");
        for (let i = 1 ; i <= 31 ; i++)
            this.dayOp.push(i);
    },
    created(){
      this.userType = localStorage.getItem('auth_type');
    },
    computed: {
        ...mapGetters(['canDo','allCategories', 'userProfile'])
    },
    watch: {
        dialog (val) {
            val || this.close()
        },
    },
    mounted() {
    }
};
</script>